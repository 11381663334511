import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

import { Table, Layout, Spin } from 'antd';
import LoadingPrompt from '../component/loading';
const { Content } = Layout;

const Price = () => {
    const [data, setData] = useState([]);
    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});
    const [isLoading, setIsLoading] = useState(true);  //控制是否還在載入中
    const [loadingTimeout, setLoadingTimeout] = useState(false);  //控制10秒後若還在載入中，則顯示錯誤訊息
    const [errorNotificationDisplayed, setErrorNotificationDisplayed] = useState(false);  //控制是否已經顯示錯誤訊息

    const location = useLocation();

    const handleChange = (pagination, filters, sorter) => {
        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };

    const iphoneData = [
        {
            key: '1',
            type: 'iPhone 15 Pro Max',
            glass: 8000,
            screen: 12000,
            originalScreen: 13500,
            certifiedBattery: 2500,
            originalBattery: 3200,
            lens: '電洽',
            faceID: '電洽',
            button: '-',
            handset: 1800,
            expansion: '電洽',
            volumeKey: 1800,
            microphone: 3000,
            boardTrial: 6000,
            backCover: "4000/電洽",
        },
        {
            key: '2',
            type: 'iPhone 15 Pro',
            glass: 7000,
            screen: 9500,
            originalScreen: 12000,
            certifiedBattery: 2500,
            originalBattery: 3200,
            lens: '電洽',
            faceID: '電洽',
            button: '-',
            handset: 1800,
            expansion: '電洽',
            volumeKey: 1800,
            microphone: 3000,
            boardTrial: 6000,
            backCover: "4000/電洽",
        },
        {
            key: '3',
            type: 'iPhone 15 Plus',
            glass: 7000,
            screen: 10000,
            originalScreen: 12200,
            certifiedBattery: 2500,
            originalBattery: 3200,
            lens: '電洽',
            faceID: '電洽',
            button: '-',
            handset: 1800,
            expansion: '電洽',
            volumeKey: 1800,
            microphone: 3000,
            boardTrial: 5500,
            backCover: "3500/電洽",
        },
        {
            key: '4',
            type: 'iPhone 15',
            glass: 6000,
            screen: 8000,
            originalScreen: 10600,
            certifiedBattery: 2500,
            originalBattery: 3200,
            lens: '電洽',
            faceID: '電洽',
            button: '-',
            handset: 1800,
            expansion: '電洽',
            volumeKey: 1800,
            microphone: 3000,
            boardTrial: 5500,
            backCover: "3500/電洽",
        },
        {
            key: '5',
            type: 'iPhone 14 Pro Max',
            glass: 7000,
            screen: 11800,
            originalScreen: 13500,
            certifiedBattery: 2500,
            originalBattery: 3200,
            lens: '2000/3000',
            faceID: 3500,
            handset: 1800,
            button: '-',
            expansion: '256GB/5000 512GB/5500',
            volumeKey: 1800,
            microphone: 2500,
            boardTrial: 5000,
            backCover: '4000/6000',
        },
        {
            key: '6',
            type: 'iPhone 14 Pro',
            glass: 7000,
            screen: 10500,
            originalScreen: 12200,
            certifiedBattery: 2500,
            originalBattery: 3200,
            lens: '2000/2500',
            faceID: 3500,
            handset: 1800,
            button: '-',
            expansion: '256GB/5000 512GB/5500',
            volumeKey: 1800,
            microphone: 2000,
            boardTrial: 5000,
            backCover: '3500/5000',
        },
        {
            key: '7',
            type: 'iPhone 14 Plus',
            glass: 4000,
            screen: 6000,
            originalScreen: 12200,
            certifiedBattery: 2000,
            originalBattery: 3200,
            lens: '2000/2500',
            faceID: 3500,
            handset: 1800,
            button: '-',
            expansion: '256GB/5000 512GB/5500',
            volumeKey: 1800,
            microphone: 2000,
            boardTrial: 4500,
            backCover: '2500/6000',
        },
        {
            key: '8',
            type: 'iPhone 14',
            glass: 4000,
            screen: 5500,
            originalScreen: 10600,
            certifiedBattery: 2000,
            originalBattery: 3200,
            lens: '2000/2500',
            faceID: 3500,
            handset: 1800,
            button: '-',
            expansion: '256GB/5000 512GB/5500',
            volumeKey: 1800,
            microphone: 2000,
            boardTrial: 4500,
            backCover: '2000/5000',
        },
        {
            key: '9',
            type: 'iPhone 13 Pro Max',
            glass: 6000,
            screen: 10000,
            originalScreen: 12200,
            certifiedBattery: 1800,
            originalBattery: 2800,
            lens: '2000/3000',
            faceID: 2500,
            handset: 1500,
            button: '-',
            expansion: '256GB/4000 512GB/4500',
            volumeKey: 1500,
            microphone: 2000,
            boardTrial: 4000,
            backCover: '3500/5000',
        },
        {
            key: '10',
            type: 'iPhone 13 Pro',
            glass: 5000,
            screen: 9000,
            originalScreen: 10600,
            certifiedBattery: 1600,
            originalBattery: 2800,
            lens: '2000/3000',
            faceID: 2500,
            handset: 1500,
            button: '-',
            expansion: '256GB/4000 512GB/4500',
            volumeKey: 1500,
            microphone: 1800,
            boardTrial: 4000,
            backCover: '3000/4500',
        },
        {
            key: '11',
            type: 'iPhone 13',
            glass: 3500,
            screen: '4500/副3000',
            originalScreen: 10600,
            certifiedBattery: 1500,
            originalBattery: 2800,
            lens: '2000/3000',
            faceID: 2500,
            handset: 1500,
            button: '-',
            expansion: '256GB/4000 512GB/4500',
            volumeKey: 1500,
            microphone: 1800,
            boardTrial: 4000,
            backCover: '3000/4500',
        },
        {
            key: '12',
            type: 'iPhone 13 Mini',
            glass: 3000,
            screen: 4500,
            originalScreen: 9000,
            certifiedBattery: 1500,
            originalBattery: 2800,
            lens: '2000/2000',
            faceID: 2500,
            handset: 1500,
            button: '-',
            expansion: '256GB/4000 512GB/4500',
            volumeKey: 1500,
            microphone: 1800,
            boardTrial: 4000,
            backCover: '2500/4000',
        },
        {
            key: '13',
            type: 'iPhone 12 Pro Max',
            glass: 3500,
            screen: '5500/副3500',
            originalScreen: 12000,
            certifiedBattery: 1500,
            originalBattery: 2500,
            lens: '2000/3500',
            faceID: 2500,
            handset: 1500,
            button: '-',
            expansion: '256GB/3500 512GB/4000',
            volumeKey: 1500,
            microphone: 1800,
            boardTrial: 3500,
            backCover: '2500/4500',
        },
        {
            key: '14',
            type: 'iPhone 12 Pro',
            glass: 3000,
            screen: '4000/副2800',
            originalScreen: 10500,
            certifiedBattery: 1300,
            originalBattery: 2500,
            lens: '2000/3500',
            faceID: 2500,
            handset: 1500,
            button: '-',
            expansion: '256GB/3500 512GB/4000',
            volumeKey: 1500,
            microphone: 1800,
            boardTrial: 3500,
            backCover: '2500/4000',
        },
        {
            key: '15',
            type: 'iPhone 12',
            glass: 3000,
            screen: '4000/副2800',
            originalScreen: 10500,
            certifiedBattery: 1300,
            originalBattery: 2500,
            lens: '2000/2000',
            faceID: 2500,
            handset: 1500,
            button: '-',
            expansion: '256GB/3500 512GB/4000',
            volumeKey: 1500,
            microphone: 1800,
            boardTrial: 3500,
            backCover: '2500/4000',
        },
        {
            key: '16',
            type: 'iPhone 12 Mini',
            glass: 3000,
            screen: '4000/副3000',
            originalScreen: 9000,
            certifiedBattery: 1200,
            originalBattery: 2500,
            lens: '2000/2000',
            faceID: 2500,
            handset: 1500,
            button: '-',
            expansion: '256GB/3500',
            volumeKey: 1500,
            microphone: 1800,
            boardTrial: 3500,
            backCover: '2300/3500',
        },
        {
            key: '17',
            type: 'iPhone 11 Pro Max',
            glass: 2500,
            screen: '3800/副2800',
            originalScreen: 12000,
            certifiedBattery: 1300,
            originalBattery: 2500,
            lens: '2000/2500',
            faceID: 2500,
            handset: 1200,
            button: '-',
            expansion: '256GB/3500 512GB/4500',
            volumeKey: 1200,
            microphone: 1800,
            boardTrial: 3000,
            backCover: '2000/3500',
        },
        {
            key: '18',
            type: 'iPhone 11 Pro',
            glass: 2000,
            screen: '3000/副2400',
            originalScreen: 10500,
            certifiedBattery: 1300,
            originalBattery: 2500,
            lens: '2000/2500',
            faceID: 2500,
            handset: 1200,
            button: '-',
            expansion: '256GB/3500 512GB/4500',
            volumeKey: 1200,
            microphone: 1800,
            boardTrial: 3000,
            backCover: '2000/3000',
        },
        {
            key: '19',
            type: 'iPhone 11',
            glass: 2500,
            screen: '2600/副2200',
            originalScreen: 7500,
            certifiedBattery: 1200,
            originalBattery: 2500,
            lens: '2000/2500',
            faceID: 2500,
            handset: 1200,
            button: '-',
            expansion: '256GB/3500 512GB/4500',
            volumeKey: 1200,
            microphone: 1200,
            boardTrial: 3000,
            backCover: '2000/3000',
        },
        {
            key: '20',
            type: 'iPhone XS Max',
            glass: 2300,
            screen: '3500/副2500',
            originalScreen: '-',
            certifiedBattery: 1200,
            originalBattery: 2500,
            lens: '2000/1800',
            faceID: 2000,
            handset: 1200,
            button: '-',
            expansion: '256GB/3000 512GB/3500',
            volumeKey: 1200,
            microphone: 1200,
            boardTrial: 2500,
            backCover: '2000/3000',
        },
        {
            key: '21',
            type: 'iPhone XS',
            glass: 2200,
            screen: '2500/副2000',
            originalScreen: '-',
            certifiedBattery: 1200,
            originalBattery: 2500,
            lens: '2000/1800',
            faceID: 2000,
            handset: 1200,
            button: '-',
            expansion: '256GB/3000 512GB/3500',
            volumeKey: 1200,
            microphone: 1200,
            boardTrial: 2500,
            backCover: '2000/3000',
        },
        {
            key: '22',
            type: 'iPhone XR',
            glass: 2000,
            screen: '2500/副2000',
            originalScreen: '-',
            certifiedBattery: 1200,
            originalBattery: 2500,
            lens: '1500/1500',
            faceID: 2000,
            handset: 1200,
            button: '-',
            expansion: '256GB/3000 512GB/4000',
            volumeKey: 1200,
            microphone: 1200,
            boardTrial: 2500,
            backCover: '2000/3000',
        },
        {
            key: '23',
            type: 'iPhone X',
            glass: 2000,
            screen: '2500/副2000',
            originalScreen: '-',
            certifiedBattery: 1000,
            originalBattery: 2500,
            lens: '1500/1500',
            faceID: 2000,
            handset: 1200,
            button: '-',
            expansion: '256GB/3000',
            volumeKey: 1200,
            microphone: 1200,
            boardTrial: 2500,
            backCover: '2000/3000',
        },
        {
            key: '24',
            type: 'iPhone SE3 SE2',
            glass: '-',
            screen: 1800,
            originalScreen: '-',
            certifiedBattery: 1000,
            originalBattery: 2200,
            lens: '1200/1200',
            faceID: '-',
            button: "900/保指紋2000",
            handset: 1200,
            expansion: '256GB/2500',
            volumeKey: 1200,
            microphone: 1200,
            boardTrial: 2500,
            backCover: '2000/2500',
        },
        {
            key: '25',
            type: 'iPhone 8 Plus',
            glass: '-',
            screen: 1800,
            originalScreen: '-',
            certifiedBattery: 800,
            originalBattery: '-',
            lens: '1500/1000',
            faceID: '-',
            button: "800/保指紋2000",
            handset: 1000,
            expansion: '256GB/2500',
            volumeKey: 1000,
            microphone: 1000,
            boardTrial: 2000,
            backCover: '2500',
        },
        {
            key: '26',
            type: 'iPhone 8',
            glass: '-',
            screen: 1800,
            originalScreen: '-',
            certifiedBattery: 800,
            originalBattery: '-',
            lens: '1200/1200',
            faceID: '-',
            button: "800/保指紋2000",
            handset: 1000,
            expansion: '256GB/2500',
            volumeKey: 1000,
            microphone: 1000,
            boardTrial: 2000,
            backCover: '2500',
        },
        {
            key: '27',
            type: 'iPhone 7 Plus',
            glass: '-',
            screen: 1800,
            originalScreen: '-',
            certifiedBattery: 800,
            originalBattery: '-',
            lens: '1200',
            faceID: '-',
            button: "800/保指紋2000",
            handset: 900,
            expansion: '256GB/2500',
            volumeKey: 1000,
            microphone: 1000,
            boardTrial: 1800,
            backCover: '2500',
        },
        {
            key: '28',
            type: 'iPhone 7',
            glass: '-',
            screen: 1800,
            originalScreen: '-',
            certifiedBattery: 800,
            originalBattery: '-',
            lens: '800',
            faceID: '-',
            button: "800/保指紋2000",
            handset: 900,
            expansion: '256GB/2500',
            volumeKey: 1000,
            microphone: 1000,
            boardTrial: 1800,
            backCover: '2200',
        },
    ];

    const iphoneColumns = [
        {
            title: 'iPhone型號',
            dataIndex: 'type',
            key: 'type',
            // filteredValue: filteredInfo.type || null,
            // onFilter: (value, record) => record.type.includes(value),
            // sorter: (a, b) => a.type.length - b.type.length,
            // sortOrder: sortedInfo.columnKey === 'type' ? sortedInfo.order : null,
            ellipsis: true,
            fixed: 'left',
            width: '150px',
            className: 'columnBold',
        },
        {
            title: '玻璃破裂',
            dataIndex: 'glass',
            key: 'glass',
            sorter: (a, b) => a.glass - b.glass,
            sortOrder: sortedInfo.columnKey === 'glass' ? sortedInfo.order : null,
            ellipsis: true,
            width: '92px',
        },
        {
            title: '螢幕總成',
            dataIndex: 'screen',
            key: 'screen',
            sorter: (a, b) => a.screen - b.screen,
            sortOrder: sortedInfo.columnKey === 'screen' ? sortedInfo.order : null,
            ellipsis: true,
            width: '120px',
        },
        {
            title: 'APPLE原廠螢幕',
            dataIndex: 'originalScreen',
            key: 'originalScreen',
            sorter: (a, b) => a.originalScreen - b.originalScreen,
            sortOrder: sortedInfo.columnKey === 'originalScreen' ? sortedInfo.order : null,
            ellipsis: true,
            width: '138px',
            className: 'fontRed',
        },
        {
            title: '認證電池',
            dataIndex: 'certifiedBattery',
            key: 'certifiedBattery',
            sorter: (a, b) => a.certifiedBattery - b.certifiedBattery,
            sortOrder: sortedInfo.columnKey === 'certifiedBattery' ? sortedInfo.order : null,
            ellipsis: true,
            width: '92px',
        },
        {
            title: 'APPLE原廠電池',
            dataIndex: 'originalBattery',
            key: 'originalBattery',
            sorter: (a, b) => a.originalBattery - b.originalBattery,
            sortOrder: sortedInfo.columnKey === 'originalBattery' ? sortedInfo.order : null,
            ellipsis: true,
            width: '138px',
            className: 'fontRed',
        },
        {
            title: '前/後鏡頭',
            dataIndex: 'lens',
            key: 'lens',
            ellipsis: true,
            width: '100px',
        },
        {
            title: 'Face ID',
            dataIndex: 'faceID',
            key: 'faceID',
            sorter: (a, b) => a.faceID - b.faceID,
            sortOrder: sortedInfo.columnKey === 'faceID' ? sortedInfo.order : null,
            ellipsis: true,
            width: '100px',
        },
        {
            title: 'HOME鍵',
            dataIndex: 'button',
            key: 'button',
            // sorter: (a, b) => a.button - b.button,
            // sortOrder: sortedInfo.columnKey === 'button' ? sortedInfo.order : null,
            ellipsis: true,
            width: '130px',
        },
        {
            title: '聽筒/震動/響鈴',
            dataIndex: 'handset',
            key: 'handset',
            sorter: (a, b) => a.handset - b.handset,
            sortOrder: sortedInfo.columnKey === 'handset' ? sortedInfo.order : null,
            ellipsis: true,
            width: '126px',
        },
            {
            title: '容量擴充',
            dataIndex: 'expansion',
            key: 'expansion',
            ellipsis: true,
            width: '190px',
        },
        {
            title: '開機鍵/音量鍵',
            dataIndex: 'volumeKey',
            key: 'volumeKey',
            sorter: (a, b) => a.volumeKey - b.volumeKey,
            sortOrder: sortedInfo.columnKey === 'volumeKey' ? sortedInfo.order : null,
            ellipsis: true,
            width: '126px',
        },
        {
            title: '充電/麥克風',
            dataIndex: 'microphone',
            key: 'microphone',
            sorter: (a, b) => a.microphone - b.microphone,
            sortOrder: sortedInfo.columnKey === 'microphone' ? sortedInfo.order : null,
            ellipsis: true,
            width: '110px',
        },
        {
            title: '主板試修/CPU另計',
            dataIndex: 'boardTrial',
            key: 'boardTrial',
            sorter: (a, b) => a.boardTrial - b.boardTrial,
            sortOrder: sortedInfo.columnKey === 'boardTrial' ? sortedInfo.order : null,
            ellipsis: true,
            width: '154px',
        },
        {
            title: '背蓋/後殼總成',
            dataIndex: 'backCover',
            key: 'backCover',
            // sorter: (a, b) => a.backCover - b.backCover,
            // sortOrder: sortedInfo.columnKey === 'backCover' ? sortedInfo.order : null,
            ellipsis: true,
            width: '110px',
        },
    ];
    
    useEffect(() => {
        axios.get("https://raw.githubusercontent.com/yan-tingciou/goldeggphone-formal/main/src/iphoneData.json")
        .then(function (response) {
            console.log(response.data);
            setData(response.data);
            setIsLoading(false);
        })
        .catch(function (error) {
            console.log(error, 123);
            setErrorNotificationDisplayed(true);
        });
        
        const timeoutId = setTimeout(() => {
            setLoadingTimeout(true);
            setIsLoading(false);
            if (!errorNotificationDisplayed && data === null) {
                setErrorNotificationDisplayed(true);
            }
        }, 10000);

        return () => {
            clearTimeout(timeoutId);
        };

    }, [location.pathname, errorNotificationDisplayed]);
  

    const iPhoneTitle = () => {
        return(<>
        <h4 style={{fontSize: '24px', fontWeight: 'bold', display: 'inline'}}>I Phone 維修價目表 </h4>
        <span className='fontRed'>左滑查看更多</span>
        </>)
    };

    return(<>
    <Content >
        <div className='contentStyle'>
        { isLoading && !loadingTimeout 
            ? <LoadingPrompt /> 
            : <Table columns={iphoneColumns} dataSource={data} onChange={handleChange} pagination={false} size='small'
                scroll={{
                    x: 100,
                }}
                summary={() => (
                    <Table.Summary >
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0} colSpan={1}>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={1} colSpan={10}>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </Table.Summary>
                )}
                style={{margin: '0 20px'}}
                title={iPhoneTitle}
                bordered
            />
        }
        </div>
    </Content>
    </>)
}
export default Price;